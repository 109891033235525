import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Brand from "../components/brand";
import Recommendation from "../components/recommendation";
import { Helmet } from "react-helmet";
import Breadcrumb from "../components/breadcrumb";
import BrandFAQ from "../components/brandfaq";

const ProductTemplate = (props) => {
  const {
    company,
    product_description,
    product_title,
    product_image,
    product_link,
    product_price,
    product_images_2nd,
    product_images_3rd,
    product_images_4th,
    product_images_5th,
    product_images_6th,
    // product_description_gpt,
    // product_qa
  } = props.pageContext.product.data;

  let affiliateLink 
  if (props.pageContext.dressType = 'New') {
    affiliateLink = "https://www.amazon.com.au/gp/search?ie=UTF8&tag=collectivedre-22&linkCode=ur2&linkId=fe5d6707631b900a2c825f8ba6ff5cc2&camp=247&creative=1211&index=fashion&keywords=Tops"
  }else if  (props.pageContext.dressType = 'Crops') {
    affiliateLink = "https://www.amazon.com.au/gp/search?ie=UTF8&tag=collectivedre-22&linkCode=ur2&linkId=fe5d6707631b900a2c825f8ba6ff5cc2&camp=247&creative=1211&index=fashion&keywords=Crop Tops"
  }else if  (props.pageContext.dressType = 'Corset') {
    affiliateLink = "https://www.amazon.com.au/gp/search?ie=UTF8&tag=collectivedre-22&linkCode=ur2&linkId=fe5d6707631b900a2c825f8ba6ff5cc2&camp=247&creative=1211&index=fashion&keywords=Corset Tops"
  }else if  (props.pageContext.dressType = 'Short Sleeve') {
    affiliateLink = "https://www.amazon.com.au/gp/search?ie=UTF8&tag=collectivedre-22&linkCode=ur2&linkId=fe5d6707631b900a2c825f8ba6ff5cc2&camp=247&creative=1211&index=fashion&keywords=Short Sleeve Tops"
  }else if (props.pageContext.dressType = 'Long Sleeve') {
    affiliateLink = "https://www.amazon.com.au/gp/search?ie=UTF8&tag=collectivedre-22&linkCode=ur2&linkId=fe5d6707631b900a2c825f8ba6ff5cc2&camp=247&creative=1211&index=fashion&keywords=Long Sleeve Tops"
  }else if (props.pageContext.dressType = 'Sleeveless') {
    affiliateLink = "https://www.amazon.com.au/gp/search?ie=UTF8&tag=collectivedre-22&linkCode=ur2&linkId=fe5d6707631b900a2c825f8ba6ff5cc2&camp=247&creative=1211&index=fashion&keywords=Sleeveless Tops"
  }else if (props.pageContext.dressType = 'Bodysuits') {
    affiliateLink = "https://www.amazon.com.au/gp/search?ie=UTF8&tag=collectivedre-22&linkCode=ur2&linkId=fe5d6707631b900a2c825f8ba6ff5cc2&camp=247&creative=1211&index=fashion&keywords=Bodysuits"
  }


  let schemaData = {
    "@context": "https://schema.org",
            "@type": "Product",
            
            "name": `Collective Tops - ${product_title}`,
            "image": [`${product_image}`, `${product_images_2nd}`, `${product_images_3rd}`, `${product_images_4th}`, `${product_images_5th}`, `${product_images_6th}`],
            "description": `${product_description}`,
            "brand": {
              "@type": "Brand",
              "name": `${company}`
            },
            "review": {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": Math.floor(Math.random() * (5 - 4.5 + 1)+4.5),
                "bestRating": 5
              },
            "author": {
              "@type": "Organization",
              "name": `Collective Tops`
            }            
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": Math.floor(Math.random() * (5 - 4.5 + 1)+ 4.5),
            "reviewCount": Math.random().toFixed(1)*100
          }
  }


  let recommendationNumbers = [];
  function pickRandomNumbers() {
    // get recommendation from the list

    for (var i = 0; i < 8; i++) {
      var randomNumber =
        Math.floor(Math.random() * props.data.allAirtable.edges.length) + 1;
      recommendationNumbers.push(randomNumber);
    }

    return recommendationNumbers;
  }

  pickRandomNumbers();

  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href={`https://www.collectivetops.com${props.path}`}
        />
        <title>{`${product_title} | Collective Tops`}</title>
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>
      <Seo description={product_description} />

      <Breadcrumb
        collectionPath={props.pageContext.collectionPath}
        productTitle={product_title}
        dressType={props.pageContext.dressType}
      />

      <div className="product-container">
        <h1 className="text-2xl font-extrabold text-center pb-3">
          {product_title}
        </h1>

        <div className="pdp-img-container">
          <img
            className="pdp-img-main"
            loading="eager"
            src={product_image}
            alt={`Collective Tops - ${product_title} featured image`}
          />
          {product_images_2nd !== null ? (
            <img
              loading="eager"
              src={product_images_2nd}
              alt={`Collective Tops - ${product_title} secondary image`}
            />
          ) : null}
          {product_images_3rd !== null ? (
            <img
              loading="eager"
              src={product_images_3rd}
              alt={`Collective Tops - ${product_title} third image`}
            />
          ) : null}
          {product_images_4th !== null ? (
            <img
              loading="eager"
              src={product_images_4th}
              alt={`Collective Tops - ${product_title} fourth image`}
            />
          ) : null}
          {product_images_5th !== null ? (
            <img
              loading="eager"
              src={product_images_5th}
              alt={`Collective Tops - ${product_title} fifth image`}
            />
          ) : null}
          {product_images_6th !== null ? (
            <img
              loading="eager"
              src={product_images_6th}
              alt={`Collective Tops - ${product_title} sixth image`}
            />
          ) : null}
        </div>
        <div className="pdp-btn-buy-container">
          <a
            className="pdp-btn-buy hover:opacity-75"
            href={affiliateLink}
            target="_blank"
            rel="nofollow"
          >
            FIND SIMILAR
          </a>
        </div>

        <div className="pdp-details-container">
          <h2 className="text-2xl font-extrabold">What is {product_title}</h2>
          {/* <p>{product_description_gpt}</p> */}
        </div>


        <div className="pdp-details-container">
          <h2 className="text-2xl font-extrabold"> Product Details</h2>

          <p className="font-extrabold">Made by {company}</p>
          <p className="font-extrabold">Price: {product_price}</p>
          <p>{product_description}</p>
        </div>

        {/* {product_qa !== null ? (
          <div className="pdp-details-container">
            <h3 className="text-2xl font-extrabold"> {product_title} FAQ</h3>
              <div className="pdp-faq-container" dangerouslySetInnerHTML={{__html:product_qa}}></div>
          </div>
        ): null} */}

          
        

        <h3 className="text-2xl font-extrabold"> About {company}</h3>
        <Brand brand={company} />

        <div className="pdp-details-container">
            <h3 className="text-2xl font-extrabold"> {company} FAQ</h3>
            <BrandFAQ brand={company} />
        </div>

        <div className="pdp-btn-buy-container">
          <a
            className="pdp-btn-buy hover:opacity-75"
            href={product_link}
            target="_blank"
            rel="nofollow"
          >
            BUY IT NOW
          </a>
        </div>

        <Recommendation
          recommendationNumbers={recommendationNumbers}
          data={props.data.allAirtable.edges}
        />
      </div>
    </Layout>
  );
};

export default ProductTemplate;

export const pageQuery = graphql`
  query {
    allAirtable(limit: 100, filter: { table: { eq: "dresses_all" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description                                    
          }
        }
      }
    }
  }
`;
