import * as React from "react"
import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

const Breadcrumb = ({ dressType, productTitle,collectionPath }) => {
  
  return (
    <nav class="w-full rounded-md mt-4 breadcrumb-container">
    <ol class="list-reset flex">
      <li>
        <a
          href="/"
          class="font-bold text-slate-950"
          >Home</a
        >
      </li>
      <li>
        <span class="mx-4 text-neutral-500 dark:text-neutral-400"> / </span>
      </li>
      <li>
        <a
          href={`/${collectionPath}`}
          class="font-bold text-slate-950"
          >{dressType} Tops</a
        >
      </li>
      <li>
        <span class="mx-2 text-neutral-500 dark:text-neutral-400">/</span>
      </li>
      <li class="text-neutral-500 dark:text-neutral-400 product-crumb">{productTitle}</li>
    </ol>
  </nav>
  )
}

export default Breadcrumb
